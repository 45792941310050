import { template as template_75c9a977f3d843c9aa865a68e4d6d5e5 } from "@ember/template-compiler";
const FKText = template_75c9a977f3d843c9aa865a68e4d6d5e5(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
