import { template as template_44a67b167ce74e3fb74f1818113a4100 } from "@ember/template-compiler";
const FKLabel = template_44a67b167ce74e3fb74f1818113a4100(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
