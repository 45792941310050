import { template as template_3f3e93507f6040ba96f238a570729f1f } from "@ember/template-compiler";
const FKControlMenuContainer = template_3f3e93507f6040ba96f238a570729f1f(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
