import { template as template_a4dd0d89315441d598165a6b607d7894 } from "@ember/template-compiler";
const WelcomeHeader = template_a4dd0d89315441d598165a6b607d7894(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
